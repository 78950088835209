import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Block, ItemAction } from "./Navigation.module.css"
export const Navigation = ({ items }) => {
  const renderItems = () =>
    items.map(({ title, slug, id }) => (
      <li key={id}>
        <Link className={ItemAction} to={`/${slug}`}>
          {title}
        </Link>
      </li>
    ))
  return <ul className={Block}>{renderItems()}</ul>
}

Navigation.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      slug: PropTypes.string,
    })
  ),
}
