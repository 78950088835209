import React, { Fragment } from "react"
import Header from "../../containers/Header"
import Footer from "../Footer/Footer"
import styles from "./Layout.module.css"

export const Layout = ({ children }) => (
  <Fragment>
    <Header />
    <div className={styles.layout} data-testid="Layout">
      {children}
    </div>
    <Footer />
  </Fragment>
)

