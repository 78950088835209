import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Header } from "../../components/Header"
import { Navigation } from "../../components/Navigation"
const HeaderContainer = () => {
  const {
    site: {
      siteMetadata: { title },
    },
    navigation: { items },
  } = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
        }
      }
      navigation: contentfulNavigation(title: { eq: "Primary" }) {
        items {
          id
          title
          slug
        }
      }
    }
  `)
  return (
    <Header title={title}>
      <Navigation items={items} />
    </Header>
  )
}

export default HeaderContainer
