import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styles from "./Footer.module.css"

const Footer = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <div className={styles.footer} data-testid="Footer">
      &copy; {title} {new Date().getFullYear()}
    </div>
  )
}

export default Footer
