import { Link } from "gatsby"
import React from "react"
import Logo from "../Logo/Logo.inline.svg"
import { ActionElement, Block } from "./Header.module.css"
export const Header = ({ title, children }) => {
  return (
    <div className={Block} data-testid="Header">
      <Link className={ActionElement} to={`/`}>
        <Logo />
      </Link>
      {children}
    </div>
  )
}

